.App {
  text-align: center;
  width: 1200px;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;
}

.homeName {
  font-family: 'Playfair Display', serif;
  font-size: larger;
}

.homeHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.homeHeaderImage {
  position: relative;
  flex-grow: 5;
  overflow: hidden;
}
.homeHeaderImage img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: auto;
}
.homeHeaderText {
  background-color: black;
  flex-grow: 1;
  text-align: center;
  padding: 120px 10px;
  width: 300px;
}
.headerAllCaps {
  color: #cf4255;
  font-weight: bold;
  text-transform: uppercase;
}
.headerProductName {
  font-family: 'Playfair Display', serif;
  color: #e2e2e2;
  font-size: 3em;
}
.headerProductDesc {
  font-family: 'Open Sans', sans-serif;
  color: #919191;
  padding: 30px 10px;
}

.buttonStyle {
  background-color: #cb4154;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  padding: 20px 60px;
  cursor: pointer;
}

.buttonStyle:hover {
  background-color: #ea5f71;
  box-shadow: 0 5px 5px rgba(0,0,0,0.2);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 5px 20px;
}

.headerTitle {
  flex-grow: 1;
  text-align: left;
  margin: 10px;
}

.header ul {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  font-size: 1.2em;
}

.header ul li {
  padding: 10px 20px;
  cursor: pointer;
}

.header ul li:hover {
  border-bottom: 1px solid #282c34;
  line-height: 10px;
}

.selectedPage {
  font-weight: bold;
}

.splitBlockContent {
  display: flex;
  flex-direction: row;
}

.splitBlockLeft {
  width: 50%;
  box-sizing: border-box;
  background-color: #efefef;
  text-align: left;
  padding: 20px 40px;
  white-space: pre-wrap;
  font-size: 1em;
}

.splitBlockLeft h2 {
  text-transform: uppercase;
  color: #cf4255;
  font-size: 1em;
}

.splitBlockRight img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: auto;
}

.splitBlockRight {
  width: 50%;
  overflow: hidden;
  position: relative;
}

.footer {
  padding: 30px;
}

.bigTitle {
  font-family: 'Playfair Display', serif;
  font-size: 3em;
  color: #5e5e5e;
}

.subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  padding-left: 77px;
  position: relative;
  top: -10px;
}

.profileDescription {
  white-space: pre-wrap;
  font-size: 1em;  
}

.socialMedia {
  padding-top: 1em;
}
.socialMedia img {
  height: 3em;
  padding-right: 5px;
}

.publicationList ul {
 list-style: none;
 padding: 0;
}

.publicationList li {
  padding-bottom: 1em;
}

.profileParagraph {
  padding-bottom: 1em; 
}

.projectList a {
  text-decoration: none;
  color: #cb4154;
}
.projectList a:hover {
  text-decoration: underline;
}

.projectList h3 {
  font-family: 'Playfair Display', serif;
  margin: 0;
}

.projectDescriptionItem {
  padding-bottom: 1em;
}

.socialMedia a {
  opacity: 0.5;
}
.socialMedia a:hover {
  opacity: 1.0;
}

.workList .workCompany, .educationList .educationSchool {
  font-family: 'Playfair Display', serif;
  font-size: 1.1em;
}

.workList .workTitle, .educationList .educationDegree {
  font-size: 1.5em;
}

.workList .workItem, .educationList .educationItem {
  padding-bottom: 1.5em;
}

.workList .workDate, .educationList .educationDate {
  font-style: italic;
}

.divSpacer {
  height: 2em;
}

.contactForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactForm h1 {
  font-family: 'Playfair Display', serif;
}

.contactForm input, .contactForm textarea {
  padding: 12px 16px;
  border: none;
  border-bottom: 1px solid #5e5e5e;
  margin-bottom: 24px;
  font-size: 1.2em;
  width: 50vw;
  font-family: 'Open Sans', sans-serif;
}
::placeholder {
  color: #8e8ea0;
  opacity: 1;
}

.contactForm textarea {
  border: 1px solid #5e5e5e;
  min-height: 20vh;
}

.loadingScreen {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Transparent black: RGB(0, 0, 0) with 0.5 opacity */
  z-index: 9999; /* Adjust the z-index to ensure the mask is on top of other elements */
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */  
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}